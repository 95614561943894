



























































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {Group, User, WorkOutPlan} from "@/api";
import groupService from "@/service/group.service";
import workOutPlanService from "@/service/workout-plan.service";
import notificationService, {NotificationType} from "@/service/notification.service";

const Auth = namespace("Auth");

@Component
export default class WorkoutPlanForm extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  groups: Group[] = [];
  loading = false;
  isUpdating = false;

  workOutPlan: WorkOutPlan = {}


  submit(): void {
    workOutPlanService.createWorkoutPlan(this.workOutPlan)
        .then(resp => {
          this.workOutPlan = resp.data;
          this.$router.push({name: 'workoutPlanEdit', params: {id: resp.data?.id?.toString()!}})
        })
        .catch(error => {
          notificationService.showNotification(NotificationType.error, error.response.data.detail)
        });
  }

  clear(): void {
    this.workOutPlan = {}
  }


  private loadGroups() {
    this.loading = true;
    groupService.getAllGroups()
        .then(resp => this.groups = resp.data)
        .catch(error => {
          notificationService.showNotification(NotificationType.error, error.response.data.detail)
        }).finally(() => {
      this.loading = false;
    })
  }

  remove(): void {
    this.workOutPlan.group = undefined;
  }

  mounted() {
    this.loadGroups();
  }

}
